@tailwind base;
@tailwind components;
@tailwind utilities;

table {
  border-spacing: 0 4px;
  border-collapse: separate;
}

table tr {
  border: none;
}

table tr th:first-child {
  @apply rounded-bl-lg;
}

table tr td:first-child {
  @apply rounded-tl-lg;
  @apply rounded-bl-lg;
}

table tr th:last-child {
  @apply rounded-br-lg;
}

table tr td:last-child {
  @apply rounded-tr-lg;
  @apply rounded-br-lg;
}

table tr {
  @apply rounded-lg;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  overflow-y: scroll;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #657297;
}

.custom-scrolbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}
